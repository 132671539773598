import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Wrapper, SEO, Header } from '../components'
import website, { description } from '../../config/website'
import { LocaleContext } from '../components/Layout'
import theme from '../styles/theme'
import { Link } from 'gatsby'
import { AddToCart } from '../templates/catalogItemStyle'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: 'Source Sans Pro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-align: center;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Selection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  p {
    text-align: left;
    margin: 0;
  }
`;
const Catalogs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .p-container {
    padding: 0 2rem;
    img {
      max-height: 500px;
    }

    .infos {
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      .descriptions {
        padding: 0 1.5rem;
        p {
          font-size: 13px;
          font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
          sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        }
        h3 {
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
          sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        }
        h6 {
          font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
          sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
          text-align: right;
          font-size: 11px;
        }
        &.ltr {
          h3, p {
            text-align: right;
          }
        }
      }
      h2 {
        font-family: 'Roboto',sans-serif;
        display: flex;
        padding: 0;
        margin: .5rem 0 0 0;
        font-size: 16px;
        font-weight: 800;
        color: #1c252b;
        div {
          display: inline
        }
      }

      .price {
        font-size: 18px;
        color: #999999;
        font-weight: bold;
        small {
          font-size: 11px;
        }
      }
      span {
        display: block;
        &.year {
          margin-bottom: 0;
          font-size: 14px;
          color: #999;
        }
        &.categories {
          margin-top: 0;
          font-size: 13px;
          color: #999;
          small {
            display: inline-block;
            margin-right: 10px;
            a {
              font-size: 13px;
              font-style: normal;
              color: #999;
              font-weight: bold;
            }
          }
        }
        &.type {
          font-size: 16px;
          font-weight: 100;
        }
        &.shipping {
          font-size: 12px
        }
        &.drouot-estimate {
          font-size: 14px
        }
      }
    }
  }
  
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    .p-container {
      padding: 0;
      img {
        width: 100%;
        height: auto;
        max-height: none;
        margin-top: 1rem;
        margin-bottom: 1rem;
        &:first-of-type {
          margin-right: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      &:last-of-type {
        margin-top: 4rem;
      }
    }
  }
`

const Shop = ({
  data: {
    catalog,
    catalog1,
    shopItems: { edges: artworksNodes },
    worksCategories: { edges: [
      { node: { data: { children: worksItems } } }
    ] }
  },
  location,
  pageContext: {
    locale
  },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const context = { slug: 'shop', parent: null };
  const orderedArtworksNodes = artworksNodes.map((i) => {
    return i;
  });
  
  return (
    <React.Fragment>
      <SEO title={`${i18n.defaultTitleAlt} - Shop`} pathname={location.pathname} locale={locale} />
      <Header {...context} />

      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0 }}>
        <Catalogs>
          {
            orderedArtworksNodes.map(({ node: { data } }, n) => {
              return (
                <React.Fragment key={`page-artworks-0-${n}`}>
                  <div className="p-container">
                    <img src={data.item.localFile.publicURL} alt={data.title.text} />
                    <div className="infos">
                      <h2>
                        <div>{data.title.text}</div>
                      </h2>
                      <span className="type">{data.type}</span>
                      <AddToCart>
                        <div className="price">
                          {parseInt(data.price)}.00 €
                          {` `}<small>(Shipping fee included)</small>
                        </div>
                      </AddToCart>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </Catalogs>
      </IndexWrapper>
      
      <IndexWrapper style={{ paddingTop: "2rem" }}>
        <Inner>
          <div>
            <Selection>
              <p>More of my work by requesting the catalog</p>
              <a
                target="_blank"
                href="mailto:dischr@gmail.com?subject=Catalog request"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  margin: '0 .5rem',
                  textAlign: 'center',
                }}
              >
                Requesting the catalog
              </a>
            </Selection>

            <Selection>
              <p>Visit my <strong>online gallery "Portraits"</strong> featuring a selection of my oils on paper</p>
              <a
                target="_blank"
                href="https://shop.dischr.com/"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  margin: '0 .5rem',
                  textAlign: 'center',
                }}
              >
                Portraits
              </a>
            </Selection>

            <Selection>
              <p>Support my work by subscribing to my <strong>Patreon page</strong></p>
              <a
                target="_blank"
                href="https://www.patreon.com/remy_disch"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  margin: '0 .5rem',
                  textAlign: 'center',
                }}
              >
                Patreon
              </a>
            </Selection>

            <Selection>
              <p>Find & buy some of my paintings on <strong>Artsper</strong></p>
              <a
                href="https://www.artsper.com/fr/artistes-contemporains/france/102408/remy-disch"
                target="_blank"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  margin: '0 .5rem',
                  textAlign: 'center',
                }}
              >
                Artsper
              </a>
            </Selection>

            <Selection>
              <p>Buy prints of my paintings on <strong>iCanvas</strong></p>
              <a
                href="https://www.icanvas.com/canvas-art-prints/artist/remy-disch"
                target="_blank"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  margin: '0 .5rem',
                  textAlign: 'center',
                }}
              >
                iCanvas
              </a>
            </Selection>
            
          </div>
          <div>
            <Link
              to="/contact"
              style={{
                fontSize: '14px',
                textDecoration: 'underline',
                display: 'block',
                margin: '1rem 0 0 0',
                textAlign: "center"
              }}
            >
              contact & informations
            </Link>
          </div>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  )
}

export default Shop

export const pageQuery = graphql`
  query catalogs {
    catalog: file(name: {eq: "b9de880e-b96a-4271-a623-d351201c6bdd_Catalog_couv"}) {
      childImageSharp {
        fixed(width: 354, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    catalog1: file(name: {eq: "64853709-a00a-4ce9-9a00-5cf318333430_fonds_atelier"}) {
      childImageSharp {
        fixed(width: 354, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    shopItems: allPrismicArtwork(
      filter: {data: {eshop: {eq: true}}}
      sort: {order: DESC, fields: data___year___raw___uid}
    ) {
      edges {
        node {
          uid
          prismicId
          data {
            meta_description
            meta_title
            title {
              html
              text
            }
            type
            available
            on_demand
            price
            item {
              alt
              url
              localFile {
                publicURL
                childImageSharp {
                  fixed(height: 800, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            cadre {
              alt
              localFile {
                childImageSharp {
                  fixed(height: 800, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image_hd {
              localFile {
                childImageSharp {
                  original {
                    height
                    src
                    width
                  }
                }
              }
            }
            categories {
              ... on PrismicArtworkDataCategories {
                category {
                  uid,
                  document {
                    data {
                      name
                    }
                  }
                }
              }
            }
            year {
              ... on PrismicArtworkDataYear {
                uid
              }
            }
          }
        }
      }
    }
    worksCategories: allPrismicCategory(filter: {uid: {eq: "works"}}) {
      edges {
        node {
          uid
          data {
            children {
              ... on PrismicCategoryDataChildren {
                item {
                  ... on PrismicCategoryDataChildrenItem {
                    uid
                    document {
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`